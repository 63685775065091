@import "@/assets/constants/index.scss";
.absolute-buttons {
  position: absolute;
  right: 0;
  top: 0;

  .el-button.single-button {
    padding: 6px 7px;
  }
}
